import Vue from 'vue';
import VueRouter from 'vue-router';

import routeMain from './routes/routeMain';

import middlewarePipeline from './middleware/middlewarePipeline'

Vue.use(VueRouter);

Vue.config.productionTip = false;

const router = routeMain;

router.beforeEach((to, from, next) => {
   if (from.name === 'home' || from.name === 'quizs' && !from.query || from.name === 'results') {
      window.history.forward(1)
   }

   if (!to.meta.middleware) {
      return next()
   }
   const middleware = to.meta.middleware
   const context = {
      to,
      from,
      next,
   }
   return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
   })
})
export default router